<template>
  <vx-card :title="title">
    <div class="vx-col mb-6 w-1/2">
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span></span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <vs-button
            class="mt-2"
            color="primary"
            type="border"
            icon-pack="feather"
            icon="icon-plus"
            @click="handleCreate()"
            >Create</vs-button
          >
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Territory</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <multiselect
            class="selectExample"
            v-model="selectedTerritory"
            :options="optionTerritory"
            :show-labels="false"
            :searchable="true"
            :multiple="false"
            :allow-empty="true"
            :group-select="true"
            :internal-search="true"
            :max-height="160"
            placeholder=" Type to search"
            track-by="id"
            label="name"
            name="territory"
            @select="handleSelectTerritory"
          />
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Zone</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <multiselect
            class="selectExample"
            v-model="operationZone"
            :options="optionOperationZone"
            :show-labels="false"
            :searchable="true"
            :multiple="false"
            :allow-empty="true"
            :group-select="true"
            :internal-search="false"
            @search-change="handleSearchOperationZone"
            :max-height="160"
            required
            placeholder=" Type to search"
            track-by="id"
            label="operationZone"
            name="operationZone"
            :custom-label="customLabelOptionOperationZone"
          />
        </div>
      </div>

      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Delivery Type</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <multiselect
            class="selectExample"
            v-model="deliveryType"
            :options="optionDeliveryType"
            :show-labels="false"
            :multiple="false"
            :allow-empty="false"
            :group-select="false"
            :max-height="160"
            placeholder=" Type to search"
            track-by="id"
            label="name"
            name="delivery type"
            :custom-label="customLabelOptionDeliveryType"
          />
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span></span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <div class="vx-row ml-4">
            <vs-button
              class="m-2"
              color="primary"
              icon-pack="feather"
              icon="icon-search"
              @click="onClickBtnFilter"
            >
              Filter
            </vs-button>
            <vs-button
              class="m-2"
              color="primary"
              icon-pack="feather"
              icon="icon-trash-2"
              @click="onClickBtnDeleteFilter"
              >Clear</vs-button
            >
          </div>
        </div>
      </div>
    </div>
    <div class="vx-row mb-12">
      <div class="vx-col w-full">
        <vs-table
          search
          stripe
          border
          description
          :sst="true"
          :data="table.data"
          :max-items="table.length"
          :total="table.total"
          @search="handleSearch"
          @change-page="handleChangePage"
          @sort="handleSort"
        >
          <template slot="header">
            <vs-dropdown vs-trigger-click class="cursor-pointer">
              <div
                class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
              >
                <span class="mr-2"
                  >{{ this.table.start }} - {{ this.table.end }} of
                  {{ this.table.total }}</span
                >
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
              </div>
              <vs-dropdown-menu>
                <vs-dropdown-item
                  v-for="item in table.limits"
                  :key="item"
                  @click="handleChangelength(item)"
                >
                  <span>{{ item }}</span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </template>
          <template slot="thead">
            <vs-th width="5%"></vs-th>
            <!-- <vs-th sort-key="name">Method Name</vs-th> -->
            <!-- <vs-th sort-key="territory_name">Action</vs-th> -->
            <vs-th sort-key="id">Delivery Type</vs-th>
            <vs-th>Allow Vehicle Class</vs-th>
            <vs-th sort-key="status">Information</vs-th>
          </template>

          <template slot-scope="{ data }">
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
              <vs-td class="whitespace-no-wrap">
                <feather-icon
                  title="Edit"
                  icon="EditIcon"
                  svgClasses="w-5 h-5 hover:text-primary stroke-current"
                  class="ml-2"
                  @click.stop="handleEdit(data[indextr].id)"
                />
                <!-- <feather-icon
                  title="Delete"
                  icon="TrashIcon"
                  svgClasses="w-5 h-5 hover:text-danger stroke-current"
                  class="ml-2"
                  @click.stop="handleDelete(data[indextr].id)"
                /> -->
              </vs-td>
              <vs-td :data="data[indextr].delivery_type">{{
                data[indextr].delivery_type.toUpperCase()
              }}</vs-td>
              <vs-td :data="data[indextr].vehicle_class_name">{{
                data[indextr].vehicle_class_name
              }}</vs-td>
              <vs-td>
                <ul>
                  <li
                    v-if="data[indextr].delivery_type == 'lco'"
                    :data="data[indextr].delivery_code"
                  >
                    Delivery Type :
                    {{ data[indextr].delivery_code }}
                  </li>
                  <li
                    v-if="data[indextr].delivery_type == 'lco'"
                    :data="data[indextr].delivery_name"
                  >
                    Delivery Name :
                    {{ data[indextr].delivery_name }}
                  </li>
                  <li
                    v-if="data[indextr].delivery_type == 'fco'"
                    :data="data[indextr].name"
                  >
                    Vehicle Name :
                    {{ data[indextr].name }}
                  </li>
                  <li
                    v-if="data[indextr].delivery_type == 'fco'"
                    :data="data[indextr].number"
                  >
                    Vehicle Number :
                    {{ data[indextr].number }}
                  </li>
                  <li
                    v-if="data[indextr].delivery_type == 'fco'"
                    :data="data[indextr].ownership"
                  >
                    OwnerShip :
                    {{ data[indextr].ownership }}
                  </li>
                  <li
                    v-if="data[indextr].delivery_type == 'fco'"
                    :data="data[indextr].supplier"
                  >
                    Supplier :
                    {{ data[indextr].supplier ? data[indextr].supplier : "-" }}
                  </li>

                  <ul v-if="data[indextr].delivery_type === 'fco'">
                    <li>Zone:</li>
                    <li
                      v-for="(item, index) in data[indextr].zones"
                      :key="index"
                    >
                      <span style="margin-left: 4%">{{ item.name }}</span>
                    </li>
                  </ul>

                  <li v-if="data[indextr].delivery_type == 'fco'">
                    Capacity :
                  </li>
                  <li
                    style="margin-left: 4%"
                    v-if="data[indextr].delivery_type == 'fco'"
                    :data="data[indextr].capacity_length"
                  >
                    Length :
                    {{ data[indextr].capacity_length.toLocaleString() }} cm
                  </li>
                  <li
                    style="margin-left: 4%"
                    v-if="data[indextr].delivery_type == 'fco'"
                    :data="data[indextr].capacity_width"
                  >
                    Width:
                    {{ data[indextr].capacity_width.toLocaleString() }} cm
                  </li>
                  <li
                    style="margin-left: 4%"
                    v-if="data[indextr].delivery_type == 'fco'"
                    :data="data[indextr].capacity_height"
                  >
                    Height:
                    {{ data[indextr].capacity_height.toLocaleString() }} cm
                  </li>
                  <li
                    style="margin-left: 4%"
                    v-if="data[indextr].delivery_type == 'fco'"
                    :data="data[indextr].capacity_volume"
                  >
                    Volume:
                    {{ data[indextr].capacity_volume.toLocaleString() }} cm<sup
                      >3</sup
                    >
                  </li>
                  <li
                    style="margin-left: 4%"
                    v-if="data[indextr].delivery_type == 'fco'"
                    :data="data[indextr].capacity_weight"
                  >
                    Weight:
                    {{
                      data[indextr].capacity_weight
                        ? data[indextr].capacity_weight.toLocaleString()
                        : "-"
                    }}
                    Kg
                  </li>
                  <li
                    v-if="data[indextr].delivery_type == '3rd party'"
                    :data="data[indextr].party_code"
                  >
                    3<sup>rd</sup> Party Code :
                    {{ data[indextr].party_code }}
                  </li>
                  <li
                    v-if="data[indextr].delivery_type == '3rd party'"
                    :data="data[indextr].party_name"
                  >
                    3<sup>rd</sup> Party Name :
                    {{ data[indextr].party_name }}
                  </li>
                </ul>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
        <vs-pagination
          style="padding-top: 5px"
          :total="table.totalPage"
          v-model="setPage"
        />
      </div>
    </div>
  </vx-card>
</template>
<script>
// import moment from "moment";

export default {
  components: {},
  data() {
    return {
      title: "Vehicle",
      baseUrl: "/api/wms/v1/delivery-code",
      deleteId: null,
      id: null,
      zoneID: null,
      territoryID: null,
      deliveryTypeName: "",
      operationZone: { name: "", id: 0 },
      optionOperationZone: [],
      deliveryType: { Name: "", id: 0 },
      optionDeliveryType: [],
      optionTerritory: [],
      selectedTerritory: {},

      table: this.tableDefaultState(),
    };
  },
  methods: {
    tableDefaultState() {
      return {
        data: [],
        length: 10,
        page: 1,
        search: "",
        order: "updated_at",
        sort: "desc",
        total: 0,

        totalpage: 1,
        totalSearch: 0,
        limits: [10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
      };
    },
    handleSearch(searching) {
      this.table.search = searching;
      this.table.page = 1;
      this.getDeliveryCode();
    },
    handleChangePage(page) {
      this.table.page = page;
      this.getDeliveryCode();
    },
    handleSort(key, active) {
      this.table.order = key;
      this.table.sort = active;
      this.getDeliveryCode();
    },
    handleChangelength(val) {
      this.table.length = val == "All" ? this.table.total : val;
      this.table.page = 1;
      this.getDeliveryCode();
    },
    handleClose() {
      this.getDeliveryCode();
    },
    handleCreate() {
      this.$router.push({
        name: "delivery-code-create",
        query: { action: "Create" },
      });
      this.id = null;
    },
    getDeliveryCode() {
      this.$vs.loading();
      this.$http
        .get(this.baseUrl + "/all", {
          params: {
            length: this.table.length,
            page: this.table.page,
            search: this.table.search,
            order: this.table.order,
            sort: this.table.sort,
            territory_id: this.territoryID,
            zone_id: this.zoneID,
            delivery_type: this.deliveryTypeName.toLowerCase(),
          },
        })
        .then((resp) => {
          this.table.totalPages = 0;
          if (resp.status == "OK") {
            this.table.total = resp.data.total_record;

            this.table.totalSearch = resp.data.total_record_search;
            this.table.length = resp.data.total_record_per_page;
            this.table.totalPage = resp.data.total_page;
            this.table.data = resp.data.records;
            this.setStartEnd();
            this.$vs.loading.close();
          } else {
            console.log(resp.data);
            this.$vs.loading.close();
          }
        });
    },
    setStartEnd() {
      let valStart =
        this.table.length * this.table.page - this.table.length + 1;
      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.table.length * this.table.page;
      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }
      if (this.table.totalSearch != 0) {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },
    // handleDelete(id) {
    //   this.deleteId = id;
    //   this.$vs.dialog({
    //     type: "confirm",
    //     color: "danger",
    //     title: `Confirm`,
    //     text: "Please confirm to delete this data",
    //     accept: this.acceptDelete,
    //   });
    // },
    // acceptDelete() {
    //   this.$vs.loading();
    //   this.$http
    //     .delete(this.baseUrl + "/" + this.deleteId)
    //     .then((resp) => {
    //       this.$vs.loading.close();
    //       if (resp.code == 200) {
    //         this.$vs.notify({
    //           color: "success",
    //           title: "Success",
    //           text: "The data was successfully deleted",
    //           position: "top-right",
    //           iconPack: "feather",
    //           icon: "icon-x-circle",
    //         });
    //       } else {
    //         this.$vs.notify({
    //           color: "danger",
    //           title: "Error",
    //           text: resp.message,
    //           position: "top-right",
    //           iconPack: "feather",
    //           icon: "icon-x-circle",
    //         });
    //       }
    //       this.getDeliveryCode();
    //     })
    //     .catch((error) => {
    //       this.$vs.loading.close();
    //       console.log(error);
    //     });
    // },
    handleEdit(id) {
      this.$router.push({
        name: "delivery-code-edit",
        query: { id: id, action: "Edit" },
      });
    },

    getOptionDeliveryType() {
      this.$vs.loading();
      this.$http.get("/api/wms/v1/delivery-code/delivery-type").then((resp) => {
        if (resp.code == 200) {
          this.optionDeliveryType = resp.data.records.filter(
            (item) => item.id !== 4
          );
        } else {
          this.$vs.notify({
            title: "Error",
            text: "Failed to get Delivery Type option",
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-check",
          });
        }
        this.$vs.loading.close();
      });
    },
    customLabelOptionDeliveryType({ Name }) {
      return `${Name}`;
    },
    getTerritory() {
      this.$vs.loading();
      this.$http.get("/api/wms/v1/master/territories").then((resp) => {
        if (resp.code == 200) {
          this.optionTerritory = resp.data.records;
        } else {
          this.$vs.notify({
            title: "Error",
            text: "Failed to get Territory option",
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-check",
          });
        }
        this.$vs.loading.close();
      });
    },
    handleSelectTerritory() {
      console.log("selectedTerritory", this.selectedTerritory);
      let TerritoryName = [];

      TerritoryName.push(this.selectedTerritory.name);

      console.log("TerritoryName", TerritoryName);
      this.getOptionOperationZone({
        territory_name: TerritoryName,
      });
    },

    getOptionOperationZone(params) {
      this.$vs.loading();
      this.$http
        .get("/api/wms/v1/delivery-code/operation-zone", {
          params: params,
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.optionOperationZone = resp.data.records;
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Failed to get Zones option",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          this.$vs.loading.close();
        });
    },
    async handleSearchOperationZone(query, id) {
      let optionOperationZones = await this.getOptionOperationZone({
        search: query,
        length: 10,
      });
      if (optionOperationZones.data.records != null) {
        // add all option
        optionOperationZones.data.records.unshift({
          id: 0,
          name: "All",
        });
        this.optionOperationZone = optionOperationZones.data.records;
      } else {
        this.optionOperationZone = [];
      }
    },
    customLabelOptionOperationZone({ name }) {
      return `${name}`;
    },

    onClickBtnFilter() {
      if (this.operationZone.id) {
        this.zoneID = this.operationZone.id;
      }
      if (this.deliveryType.Name) {
        this.deliveryTypeName = this.deliveryType.Name;
      }
      if (this.selectedTerritory.id) {
        this.territoryID = this.selectedTerritory.id;
      }

      this.getDeliveryCode();
    },
    onClickBtnDeleteFilter() {
      this.zoneID = 0;
      this.territoryID = 0;
      this.selectedTerritory = {};
      this.deliveryTypeName = "";
      (this.operationZone = { name: "", id: 0 }),
        (this.deliveryType = { Name: "", id: 0 }),
        this.getDeliveryCode();
    },
  },
  filters: {
    formatStatus(val) {
      if (val == 1) {
        return "Active";
      } else {
        return "Inactive";
      }
    },
    // formatDate(val) {
    //   return moment().add(7, "days").format("YYYY-MM-DD");
    // },
  },
  watch: {},
  mounted() {
    this.getOptionDeliveryType();
    this.getTerritory();
    this.getDeliveryCode();
  },
  computed: {
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
  },
};
</script>

<style scoped>
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-left: 1px solid #7367f0;
  min-height: 800px;
  padding: 5px;
}
</style>
